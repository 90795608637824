var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-1M",
                    defaultEnd: "1M",
                    label: "LBL0001410",
                    name: "actionCompleteRequestDates",
                  },
                  model: {
                    value: _vm.searchParam.actionCompleteRequestDates,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.searchParam,
                        "actionCompleteRequestDates",
                        $$v
                      )
                    },
                    expression: "searchParam.actionCompleteRequestDates",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: _vm.isPop === true ? true : false,
                    codeGroupCd: "IBM_TASK_TYPE_CD",
                    rejectItems: ["ITT0000145"],
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "ibmTaskTypeCd",
                    label: "LBLWORK",
                  },
                  model: {
                    value: _vm.searchParam.ibmTaskTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "ibmTaskTypeCd", $$v)
                    },
                    expression: "searchParam.ibmTaskTypeCd",
                  },
                }),
              ],
              1
            ),
            (_vm.isPop === true ? false : true)
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        stepperGrpCd: "IBM_STEP_CD",
                        type: "search",
                        itemText: "stepperMstNm",
                        itemValue: "stepperMstCd",
                        name: "ibmStepCd",
                        label: "LBLSTEPPROGRESS",
                      },
                      model: {
                        value: _vm.searchParam.ibmStepCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "ibmStepCd", $$v)
                        },
                        expression: "searchParam.ibmStepCd",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-dept", {
                  attrs: {
                    plantCd: _vm.searchParam.plantCd,
                    isFirstValue: false,
                    type: "search",
                    label: "LBL0001421",
                    name: "improveRequestDeptCd",
                  },
                  model: {
                    value: _vm.searchParam.improveRequestDeptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "improveRequestDeptCd", $$v)
                    },
                    expression: "searchParam.improveRequestDeptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-dept", {
                  attrs: {
                    plantCd: _vm.searchParam.plantCd,
                    isFirstValue: _vm.isPop === true ? false : true,
                    type: "search",
                    label: "LBL0001417",
                    name: "actionDeptCd",
                  },
                  model: {
                    value: _vm.searchParam.actionDeptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "actionDeptCd", $$v)
                    },
                    expression: "searchParam.actionDeptCd",
                  },
                }),
              ],
              1
            ),
            (_vm.isPop === true ? false : true)
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        codeGroupCd: "IBM_CLASS_CD",
                        type: "search",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "ibmClassCd",
                        label: "LBL0001411",
                      },
                      model: {
                        value: _vm.searchParam.ibmClassCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "ibmClassCd", $$v)
                        },
                        expression: "searchParam.ibmClassCd",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.isPop === true ? false : true)
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        isFlag: true,
                        falseLabel: "All",
                        label: "LBL0001412",
                        name: "overFlag",
                      },
                      model: {
                        value: _vm.searchParam.overFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "overFlag", $$v)
                        },
                        expression: "searchParam.overFlag",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "개선 목록",
            tableId: "impr01",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.gridHeight,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "actionCompleteRequestDate"
                    ? [
                        _c(
                          "span",
                          {
                            style:
                              props.row.approvalStatusCd === "ASC9999999"
                                ? "text-decoration: line-through"
                                : "",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(props.row.actionCompleteRequestDate) +
                                " "
                            ),
                            props.row.completeDateOver > 0 &&
                            props.row.ibmStepCd !== "IS00000001" &&
                            props.row.approvalStatusCd !== "ASC9999999"
                              ? _c("q-badge", {
                                  staticClass: "blinking",
                                  attrs: {
                                    color: "negative",
                                    label: _vm.$label("LBL0001414"),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        props.row.approvalStatusCd === "ASC9999999"
                          ? _c("span", [
                              _vm._v(
                                " 👉 " + _vm._s(props.row.targetDate) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: {
                      disabled: !_vm.grid.data || _vm.grid.data.length === 0,
                      label: "LBL0001425",
                      icon: "print",
                    },
                    on: { btnClicked: _vm.imprResultPrint },
                  }),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLREG", icon: "add" },
                        on: { btnClicked: _vm.addEtcImpr },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }